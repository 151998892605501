import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { omit } from 'lodash';

// Types
import {
    IPushAudioPlayerQueuePayload,
    IRemoveAudioPlayerQueuePayload,
    IRootState,
    IUpdateAudioPlayerSourcePayload,
    IUpdateBookmarkBooksPayload,
    IUpdateLikedBooksPayload,
    IUpdatePlayedBooksPayload,
    IUpdateViewedBooksPayload,
} from '@Src/store/types';

// Constant
import { checkJSON } from '@Utils';
import { LOCALSTORAGE_KEY } from '@Src/constant';

const getInitialState = (initState): IRootState => {
    let localStorageState = {};

    if (typeof Window !== 'undefined' && typeof localStorage !== 'undefined') {
        const storage = localStorage.getItem(LOCALSTORAGE_KEY);

        if (storage && checkJSON(storage)) {
            localStorageState = JSON.parse(storage);
        }
    }

    return {
        ...initState,
        ...omit(localStorageState, ['audioplayer', 'isShowLoading']),
    };
};

const initialState: IRootState = {
    theme: {
        isDark: false,
    },
    /**
     * User unique id
     */
    uuid: '',
    /**
     * List books played history
     */
    playedBooks: null,
    /**
     * List books viewed by user
     */
    viewedBooks: null,
    /**
     * List books bookmark by user
     */
    bookmarkedBooks: null,
    /**
     * List books liked by user
     */
    likedBooks: null,
    /**
     * Current audiobook played
     */
    audioplayer: {
        isPlay: false,
        isShow: false,
        isLoading: false,
        isError: false,
        book: {},
        audio: {},
        queue: [],
    },
    isShowLoading: false,
};

const appReducer = createSlice({
    name: 'app',
    initialState: getInitialState(initialState),
    reducers: {
        initLocalStorage: (state) => {
            return state;
        },
        rehydrate: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        showAudioPlayer: (state) => {
            state.audioplayer.isShow = true;
        },
        hideAudioPlayer: (state) => {
            state.audioplayer.isShow = false;
        },
        playAudioPlayer: (state) => {
            // Update current book played
            state.audioplayer.isPlay = true;
        },
        pauseAudioPlayer: (state) => {
            state.audioplayer.isPlay = false;
        },
        updateAudioPlayerLoading: (state, action) => {
            state.audioplayer.isLoading = action.payload;
        },
        updateAudioPlayerError: (state, action) => {
            state.audioplayer.isError = action.payload;
        },
        updateAudioPlayerSource: (state, action: PayloadAction<IUpdateAudioPlayerSourcePayload>) => {
            const { id, url, url2 } = action.payload;

            state.audioplayer.audio = {
                id,
                url,
                url2,
            };
        },
        updateViewedBooks: (state, action: PayloadAction<IUpdateViewedBooksPayload>) => {
            const { bookId } = action.payload;

            if (bookId) {
                const books = state?.viewedBooks?.books || [];

                const viewedBooks = books
                    .filter((book, index) => book.bookId !== bookId && books.length - index <= 25)
                    .concat([
                        {
                            bookId,
                        },
                    ]);

                state.viewedBooks = {
                    books: viewedBooks,
                    updatedAt: Date.now(),
                };
            }
        },
        updatePlayedBooks: (state, action: PayloadAction<IUpdatePlayedBooksPayload>) => {
            const { bookId, episode } = action.payload;

            if (bookId) {
                let playedBooks = state?.playedBooks?.books || [];

                if (episode) {
                    let isBookBeforePlayed = false;
                    playedBooks = playedBooks.map((book) => {
                        // Update episode of book exists
                        if (book.bookId === bookId) {
                            isBookBeforePlayed = true;

                            let episodes = (book?.episodes || []).filter(
                                (bookEpisode) => bookEpisode.episodeId !== episode.episodeId
                            );

                            // Add current episode
                            episodes = episodes.concat([
                                {
                                    ...episode,
                                    updatedAt: Date.now(),
                                },
                            ]);

                            return {
                                ...book,
                                episodes: episodes,
                                updatedAt: Date.now(),
                            };
                        }

                        return book;
                    });

                    // Add new book
                    if (!isBookBeforePlayed) {
                        playedBooks = playedBooks.concat({
                            bookId,
                            episodes: [{ ...episode, updatedAt: Date.now() }],
                            updatedAt: Date.now(),
                        });
                    }
                } else {
                    playedBooks = playedBooks
                        .filter((book, index) => book.bookId !== bookId)
                        .concat([
                            {
                                bookId,
                                updatedAt: Date.now(),
                            },
                        ]);
                }

                state.playedBooks = {
                    books: playedBooks,
                    updatedAt: Date.now(),
                };
            }
        },
        updateBookmarkBooks: (state, action: PayloadAction<IUpdateBookmarkBooksPayload>) => {
            const { bookId, isRemove = false } = action.payload;

            if (bookId) {
                const books = state?.bookmarkedBooks?.books || [];

                let bookmarkBooks = books.filter((book, index) => book.bookId !== bookId);

                if (!isRemove) {
                    bookmarkBooks = bookmarkBooks.concat([
                        {
                            bookId,
                            updatedAt: Date.now(),
                        },
                    ]);
                }

                state.bookmarkedBooks = {
                    books: bookmarkBooks,
                    updatedAt: Date.now(),
                };
            }
        },
        updateLikedBooks: (state, action: PayloadAction<IUpdateLikedBooksPayload>) => {
            const { bookId, isRemove = false } = action.payload;

            if (bookId) {
                const books = state?.likedBooks?.books || [];

                let likedBooks = books.filter((book, index) => book.bookId !== bookId);

                if (!isRemove) {
                    likedBooks = likedBooks.concat([
                        {
                            bookId,
                            updatedAt: Date.now(),
                        },
                    ]);
                }

                state.likedBooks = {
                    books: likedBooks,
                    updatedAt: Date.now(),
                };
            }
        },
        pushAudioPlayerQueue: (state, action: PayloadAction<IPushAudioPlayerQueuePayload>) => {
            const { name = '', args } = action.payload;
            const queue = state.audioplayer.queue;

            if (name) {
                state.audioplayer.queue = queue.concat([{ name, args: { ...args } }]);
            }
        },
        removeAudioPlayerQueue: (state, action: PayloadAction<IRemoveAudioPlayerQueuePayload>) => {
            const { name } = action.payload;

            if (name) {
                state.audioplayer.queue = (state.audioplayer.queue || []).filter((queue) => {
                    return queue.name !== name;
                });
            }
        },
        showLoading: (state, action) => ({
            ...state,
            isShowLoading: true,
        }),
    },
    extraReducers: {
        [HYDRATE]: (state) => ({
            ...state,
        }),
    },
});

export const {
    initLocalStorage,
    rehydrate,
    showAudioPlayer,
    hideAudioPlayer,
    playAudioPlayer,
    pauseAudioPlayer,
    updateAudioPlayerLoading,
    updateAudioPlayerError,
    updateAudioPlayerSource,
    pushAudioPlayerQueue,
    removeAudioPlayerQueue,
    showLoading,
    updateViewedBooks,
    updatePlayedBooks,
    updateBookmarkBooks,
    updateLikedBooks,
} = appReducer.actions;

export default appReducer.reducer;
