import React, { useState, useEffect } from 'react';

const SvgDefinition = () => {
    return (
        <svg width="0" height="0" className="hidden" id="core-icon" xmlns="http://www.w3.org/2000/svg">
            <symbol
                id="home"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
            </symbol>

            <symbol id="menu" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                <path d="M2 8H14" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2 4H14" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2 12H14" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            </symbol>
            <symbol id="close" stroke="currentColor" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4L4 12" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4 4L12 12" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            </symbol>
            <symbol id="arrow-up" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15 12.5L10 7.5L5 12.5"
                    stroke="black"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </symbol>
            <symbol
                id="arrow-down"
                viewBox="0 0 16 16"
                fill="none"
                stroke="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M4 6L8 10L12 6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            </symbol>
            <symbol id="user" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13 12.25H5C3.74067 12.2514 2.53333 12.7523 1.64285 13.6428C0.752368 14.5333 0.251456 15.7406 0.25 17V18C0.250582 18.4639 0.435143 18.9087 0.763206 19.2368C1.09127 19.5648 1.53605 19.7494 2 19.75H16C16.464 19.7494 16.9087 19.5648 17.2368 19.2368C17.5649 18.9087 17.7494 18.4639 17.75 18V17C17.7485 15.7406 17.2476 14.5333 16.3571 13.6428C15.4667 12.7523 14.2593 12.2514 13 12.25ZM16.25 18C16.2492 18.066 16.2226 18.1292 16.1759 18.1759C16.1292 18.2226 16.0661 18.2492 16 18.25H2C1.93395 18.2492 1.87082 18.2226 1.82411 18.1759C1.7774 18.1292 1.7508 18.066 1.75 18V17C1.75101 16.1383 2.09374 15.3123 2.70301 14.703C3.31229 14.0937 4.13836 13.751 5 13.75H13C13.8616 13.751 14.6877 14.0937 15.297 14.703C15.9063 15.3123 16.249 16.1383 16.25 17V18ZM9 10.75C10.0398 10.7534 11.0572 10.4479 11.9232 9.87232C12.7891 9.29674 13.4647 8.47698 13.8642 7.51697C14.2636 6.55697 14.3691 5.49996 14.167 4.47996C13.965 3.45997 13.4646 2.52295 12.7294 1.78769C11.9941 1.05244 11.0571 0.552076 10.0371 0.350049C9.01712 0.148022 7.96011 0.25343 7.0001 0.652908C6.04009 1.05239 5.22033 1.72794 4.64476 2.59391C4.06918 3.45989 3.76372 4.47727 3.7671 5.51707C3.77326 6.90303 4.32655 8.23046 5.30658 9.21049C6.28661 10.1905 7.61405 10.7438 9 10.75ZM9 1.74997C9.74167 1.7466 10.4677 1.96323 11.0862 2.37247C11.7048 2.78172 12.1881 3.36519 12.475 4.04911C12.762 4.73303 12.8397 5.48668 12.6983 6.21476C12.5569 6.94283 12.2028 7.61264 11.6808 8.13947C11.1587 8.6663 10.4922 9.02649 9.76542 9.1745C9.03866 9.32252 8.28434 9.25169 7.59783 8.971C6.91132 8.6903 6.32346 8.21234 5.9086 7.59755C5.49373 6.98275 5.27049 6.25874 5.2671 5.51707C5.2632 5.02416 5.35683 4.53534 5.54263 4.07877C5.72842 3.62219 6.00269 3.20688 6.34965 2.85674C6.69661 2.5066 7.1094 2.22855 7.56427 2.03861C8.01913 1.84866 8.50707 1.75057 9 1.74997Z"
                    fill="currentColor"
                />
            </symbol>
            <symbol id="search-outlined" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.75 9.9129C4.75 7.06839 7.08056 4.75 9.9709 4.75C12.8612 4.75 15.1918 7.06839 15.1918 9.9129C15.1918 11.3386 14.6063 12.6322 13.6583 13.5679C13.647 13.5779 13.6359 13.5883 13.6251 13.5991C13.6184 13.6057 13.6119 13.6124 13.6056 13.6192C12.6667 14.5202 11.3859 15.0758 9.9709 15.0758C7.08056 15.0758 4.75 12.7574 4.75 9.9129ZM14.1177 15.1566C12.9746 16.046 11.534 16.5758 9.9709 16.5758C6.26597 16.5758 3.25 13.5996 3.25 9.9129C3.25 6.2262 6.26597 3.25 9.9709 3.25C13.6758 3.25 16.6918 6.2262 16.6918 9.9129C16.6918 11.5051 16.1293 12.9647 15.1917 14.1094L20.5306 19.4708C20.8229 19.7643 20.8219 20.2392 20.5284 20.5314C20.2349 20.8237 19.76 20.8227 19.4678 20.5292L14.1177 15.1566Z"
                    fill="currentColor"
                />
            </symbol>
            <symbol id="clock" className="mr-1" viewBox="0 0 1024 1024">
                <path d="M512 53.35c253.305 0 458.65 205.344 458.65 458.65s-205.344 458.65-458.65 458.65v0c-253.305 0-458.65-205.344-458.65-458.65s205.344-458.65 458.65-458.65v0zM512 117.35c-217.959 0-394.65 176.691-394.65 394.65s176.691 394.65 394.65 394.65h0c217.959 0 394.65-176.691 394.65-394.65s-176.691-394.65-394.65-394.65v0zM512 181.35c17.664 0 32 14.336 32 32v285.338l161.28 161.28c5.792 5.792 9.374 13.793 9.374 22.63 0 17.675-14.329 32.004-32.004 32.004-8.838 0-16.839-3.582-22.63-9.374v0l-170.65-170.65c-5.779-5.78-9.357-13.761-9.37-22.577l-0-0.002v-298.65c0-17.715 14.336-32 32-32z"></path>
            </symbol>
            <symbol id="sound" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"
                />
            </symbol>
            <symbol id="sound-mute" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z"
                />
            </symbol>
            <svg id="star" fill="currentColor" viewBox="0 0 16 16">
                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
            </svg>
            <svg id="star-fill" fill="currentColor" viewBox="0 0 16 16">
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
            </svg>
            <svg id="star-half" fill="currentColor" viewBox="0 0 16 16">
                <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z" />
            </svg>
            <symbol id="loading" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <g transform="rotate(0 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#ffffff">
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="1s"
                            begin="-0.9166666666666666s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(30 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#ffffff">
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="1s"
                            begin="-0.8333333333333334s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(60 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#ffffff">
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="1s"
                            begin="-0.75s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(90 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#ffffff">
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="1s"
                            begin="-0.6666666666666666s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(120 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#ffffff">
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="1s"
                            begin="-0.5833333333333334s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(150 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#ffffff">
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="1s"
                            begin="-0.5s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(180 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#ffffff">
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="1s"
                            begin="-0.4166666666666667s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(210 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#ffffff">
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="1s"
                            begin="-0.3333333333333333s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(240 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#ffffff">
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="1s"
                            begin="-0.25s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(270 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#ffffff">
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="1s"
                            begin="-0.16666666666666666s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(300 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#ffffff">
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="1s"
                            begin="-0.08333333333333333s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(330 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#ffffff">
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="1s"
                            begin="0s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
            </symbol>
            <symbol
                id="phone"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                />
            </symbol>
            <symbol
                id="clock-play"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path d="M12 7v5l2 2"></path>
                <path d="M17 22l5 -3l-5 -3z"></path>
                <path d="M13.017 20.943a9 9 0 1 1 7.831 -7.292"></path>
            </symbol>
            <symbol
                id="bookmark"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"
                />
            </symbol>
            <symbol id="bookmark-filled" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path
                    fillRule="evenodd"
                    d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z"
                    clipRule="evenodd"
                />
            </symbol>

            <symbol
                id="heart"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                />
            </symbol>
            <symbol
                id="download"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                />
            </symbol>
            <symbol id="backward-5-seconds" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12.3806 16.9203H10.0906C9.68062 16.9203 9.34062 16.5803 9.34062 16.1703C9.34062 15.7603 9.68062 15.4203 10.0906 15.4203H12.3806C12.8106 15.4203 13.1606 15.0703 13.1606 14.6403C13.1606 14.2103 12.8106 13.8603 12.3806 13.8603H10.0906C9.85062 13.8603 9.62062 13.7403 9.48062 13.5503C9.34062 13.3603 9.30062 13.1003 9.38062 12.8703L10.1406 10.5803C10.2406 10.2703 10.5306 10.0703 10.8506 10.0703H13.9106C14.3206 10.0703 14.6606 10.4103 14.6606 10.8203C14.6606 11.2303 14.3206 11.5703 13.9106 11.5703H11.3906L11.1306 12.3603H12.3806C13.6406 12.3603 14.6606 13.3803 14.6606 14.6403C14.6606 15.9003 13.6406 16.9203 12.3806 16.9203Z" />
                <path d="M12.0016 3.47945C11.9216 3.47945 11.8416 3.48945 11.7616 3.48945L12.5816 2.46945C12.8416 2.14945 12.7916 1.66945 12.4616 1.41945C12.1316 1.16945 11.6716 1.20945 11.4116 1.53945L9.44156 3.99945C9.43156 4.00945 9.43156 4.01945 9.42156 4.03945C9.39156 4.07945 9.37156 4.12945 9.35156 4.16945C9.33156 4.21945 9.31156 4.25945 9.30156 4.29945C9.29156 4.34945 9.29156 4.38945 9.29156 4.43945C9.29156 4.48945 9.29156 4.53945 9.29156 4.58945C9.29156 4.60945 9.29156 4.61945 9.29156 4.63945C9.30156 4.66945 9.32156 4.68945 9.33156 4.71945C9.35156 4.76945 9.37156 4.80945 9.39156 4.85945C9.42156 4.89945 9.45156 4.93945 9.49156 4.96945C9.51156 4.99945 9.52156 5.02945 9.55156 5.04945C9.57156 5.05945 9.58156 5.06945 9.60156 5.07945C9.63156 5.09945 9.65156 5.10945 9.68156 5.11945C9.73156 5.14945 9.79156 5.16945 9.85156 5.17945C9.88156 5.19945 9.91156 5.19945 9.94156 5.19945C9.97156 5.19945 9.99156 5.20945 10.0216 5.20945C10.0516 5.20945 10.0716 5.19945 10.0916 5.18945C10.1216 5.18945 10.1516 5.19945 10.1816 5.18945C10.8216 5.03945 11.4216 4.96945 11.9916 4.96945C16.4816 4.96945 20.1316 8.61945 20.1316 13.1095C20.1316 17.5994 16.4816 21.2495 11.9916 21.2495C7.50156 21.2495 3.85156 17.5994 3.85156 13.1095C3.85156 11.3695 4.42156 9.68945 5.50156 8.24945C5.75156 7.91945 5.68156 7.44945 5.35156 7.19945C5.02156 6.94945 4.55156 7.01945 4.30156 7.34945C3.02156 9.04945 2.35156 11.0395 2.35156 13.1095C2.35156 18.4195 6.67156 22.7495 11.9916 22.7495C17.3116 22.7495 21.6316 18.4295 21.6316 13.1095C21.6316 7.78945 17.3116 3.47945 12.0016 3.47945Z" />
            </symbol>
            <symbol id="forward-5-seconds" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.4791 7.09052C19.2191 6.77052 18.7491 6.72052 18.4291 6.98052C18.1091 7.24052 18.0591 7.71052 18.3191 8.03052C19.4491 9.43052 20.0791 11.0905 20.1391 12.8305C20.2991 17.3105 16.7791 21.0905 12.2891 21.2405C7.79906 21.4005 4.02906 17.8805 3.86906 13.4005C3.70906 8.92052 7.22906 5.14052 11.7191 4.99052C12.2891 4.97052 12.8891 5.02052 13.5391 5.15052C13.5791 5.16052 13.6191 5.15052 13.6591 5.15052C13.7591 5.20052 13.8791 5.23052 13.9891 5.23052C14.1591 5.23052 14.3191 5.18052 14.4591 5.06052C14.7791 4.80052 14.8291 4.33052 14.5791 4.01052L12.5991 1.54052C12.3391 1.22052 11.8691 1.16052 11.5491 1.42052C11.2291 1.68052 11.1791 2.15052 11.4291 2.47052L12.2591 3.50052C12.0691 3.49052 11.8691 3.48052 11.6791 3.49052C6.36906 3.67052 2.19906 8.15052 2.38906 13.4605C2.57906 18.7705 7.04906 22.9405 12.3591 22.7505C17.6691 22.5605 21.8391 18.0905 21.6491 12.7805C21.5591 10.7105 20.8191 8.74052 19.4791 7.09052Z" />
                <path d="M12.3806 16.9203H10.0906C9.68062 16.9203 9.34062 16.5803 9.34062 16.1703C9.34062 15.7603 9.68062 15.4203 10.0906 15.4203H12.3806C12.8106 15.4203 13.1606 15.0703 13.1606 14.6403C13.1606 14.2103 12.8106 13.8603 12.3806 13.8603H10.0906C9.85062 13.8603 9.62062 13.7403 9.48062 13.5503C9.34062 13.3603 9.30062 13.1003 9.38062 12.8703L10.1406 10.5803C10.2406 10.2703 10.5306 10.0703 10.8506 10.0703H13.9106C14.3206 10.0703 14.6606 10.4103 14.6606 10.8203C14.6606 11.2303 14.3206 11.5703 13.9106 11.5703H11.3906L11.1306 12.3603H12.3806C13.6406 12.3603 14.6606 13.3803 14.6606 14.6403C14.6606 15.9003 13.6406 16.9203 12.3806 16.9203Z" />
            </symbol>
        </svg>
    );
};

interface SvgIconType {
    /**
     * Tên của icon, là trường id khi khai báo svg definition
     */
    name: string;
    /**
     * Kích thước của icon
     */
    size?: number;
    /**
     * Màu sắc của icon, ví dụ: #000000
     */
    fill?: string;
    /**
     * Những classs sẽ được thêm vào wrapper của icon
     */
    className?: string;
}

const SvgIcon = (props: SvgIconType) => {
    const { name, size = 16, fill = 'currentColor', className = '' } = props;
    const [iconUrl, setIconUrl] = useState('');

    useEffect(() => {
        const url = `${window.location.href}#${name}`;

        setIconUrl(url);
    }, [name]);

    return (
        <svg
            version="1.1"
            width={size}
            height={size}
            fill={fill}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
            <use xlinkHref={iconUrl} color={fill} />
        </svg>
    );
};

export { SvgIcon, SvgDefinition };
