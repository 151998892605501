const ENV = process.env.ENV || 'develop';
const SITE_URL = process.env.SITE_URL || '';
const API_SERVER = process.env.API_SERVER || '';
const API_BACKEND_URL = process.env.API_BACKEND_URL || process.env.NEXT_PUBLIC_API_BACKEND_URL;
const UPLOAD_SERVER = process.env.UPLOAD_SERVER || process.env.NEXT_PUBLIC_UPLOAD_SERVER;

const REDIS_HOST = process.env.REDIS_HOST;
const REDIS_PORT: number = process.env.REDIS_PORT ? +process.env.REDIS_PORT : 0;

const DEFAULT_PAGE_SIZE = 25;
const MAX_PAGE_NUMBER = 200; // Maximum page number user can view
const LOCALSTORAGE_KEY = 'nghesachnoi-persist';

const MOBILE_DIMENSION_WIDTH = 1024;

const KEY_CODE = {
    ENTER: 13,
};

export {
    ENV,
    SITE_URL,
    API_SERVER,
    API_BACKEND_URL,
    UPLOAD_SERVER,
    REDIS_HOST,
    REDIS_PORT,
    DEFAULT_PAGE_SIZE,
    MAX_PAGE_NUMBER,
    LOCALSTORAGE_KEY,
    MOBILE_DIMENSION_WIDTH,
    KEY_CODE,
};
