import { get } from 'lodash';

export const parseError = (error: Record<string, any>) => {
    const code = get(error, 'response.data.error.code', '') || get(error, 'response.status', '');
    const message = get(error, 'response.data.error.title', '');
    const detail = get(error, 'response.data.error.detail', '');

    console.log('error', error);

    return {
        isError: true,
        code,
        message: message || detail || 'Request invalid',
        data: null,
    };
};

export const parseResponse = (data: any) => {
    return {
        isError: false,
        data,
    };
};

export const checkJSON = (_str: string): boolean => {
    let result = true;

    try {
        JSON.parse(_str);
    } catch (e) {
        result = false;
    }

    return result;
};

/**
 * Remove Vietnamese accents
 * @param str
 * @returns {string}
 */
export const removeAccents = (str) =>
    str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/đ/g, 'd')
        .replace(/Đ/g, 'D');

/**
 * Generate slug
 * @param str
 * @returns {string}
 */
export const generateSlug = (str) => {
    return removeAccents(str)
        .toLocaleLowerCase()
        .trim()
        .replace(/[^\w\s]/g, '')
        .replace(/\s/g, '-');
};

export const getHoursFromMinutes = (minutes: number) => {
    let hours = 0;
    let minus = 0;

    if (minutes > 60) {
        hours = Math.floor(minutes / 60);
        minus = minutes % 60;
    } else {
        minus = minutes;
    }

    return {
        hours,
        minutes: minus,
    };
};

export const getDesiredWords = (text: string, wordCount: number) => {
    try {
        if (!text) {
            return '';
        }

        const words = text.replaceAll('\n', '').replaceAll('#', '').replaceAll('"', '').split(' ');
        if (words.length <= wordCount) {
            return text;
        }

        const desiredWords = words.slice(0, wordCount);
        return desiredWords.join(' ') + '...';
    } catch (error) {
        return text;
    }
};

export const downloadPDF = (url: string, fileName?: string, callback?: (args) => void) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onloadstart = function () {
        if (typeof callback === 'function') {
            callback('onloadstart');
        }
    };

    xhr.onload = function () {
        if (typeof callback === 'function') {
            callback('onload');
        }

        if (xhr.status === 200) {
            const blob = new Blob([xhr.response], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName || 'file.pdf';
            link.click();
        }
    };
    xhr.send();
};

/**
 * calculate words reading minute
 * @param text: string want to calculate minute to read
 */
export const calculateMinutesReading = (text: string): number => {
    if (text) {
        // Thời gian đọc (phút) = Số từ trong đoạn văn bản / Số từ trung bình đọc được trong một phút

        // Tính số từ trong đoạn văn bản
        const wordCount = text.split(/\s+/).length;

        // Tính thời gian đọc (phút) với tốc độ đọc trung bình là 200 từ/phút
        return Math.ceil(wordCount / 200);
    }

    return 3;
};
