import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Provider } from 'react-redux';
import type { AppProps } from 'next/app';
import NProgress from 'nprogress';
import 'reflect-metadata';

// Components
import { SvgDefinition } from '@Components/svg-definition';

// Store
import { wrapper } from '@Src/store/store';

// Styles
import '@Assets/css/globals.scss';
import 'antd/dist/reset.css';
import 'nprogress/nprogress.css';

function App({ Component, ...rest }: AppProps) {
    const { store, props } = wrapper.useWrappedStore(rest);
    const router = useRouter();

    useEffect(() => {
        const handleStart = (url: string) => {
            NProgress.start();
        };

        const handleStop = () => {
            NProgress.done();
        };

        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleStop);
        router.events.on('routeChangeError', handleStop);

        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleStop);
            router.events.off('routeChangeError', handleStop);
        };
    }, [router]);

    return (
        <Provider store={store}>
            <WrappedComponent Component={Component} props={props} />
        </Provider>
    );
}

const WrappedComponent = ({ Component, props }) => {
    return (
        <>
            <Component {...props.pageProps} />
            <SvgDefinition />
        </>
    );
};

export default App;
