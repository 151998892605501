import { all, takeEvery, put } from 'redux-saga/effects';
import { pick } from 'lodash';

// Actions
// import { updateAudioPlayerSource, updatePlayedBooks } from './reducer';

// Constant
import { LOCALSTORAGE_KEY } from '@Src/constant';

// function* handleAudioPlayerSourceChanged(args) {
//     try {
//         const { bookId = '', id = '' } = args.payload;
//
//         if (bookId) {
//             yield put(
//                 updatePlayedBooks({
//                     bookId,
//                     episode: {
//                         episodeId: id,
//                         duration: 1,
//                     },
//                 })
//             );
//         }
//         //
//     } catch (err) {
//         // yield put(failure(err))
//     }
// }
//
export default function* rootSaga() {
    // yield all([takeEvery(updateAudioPlayerSource, handleAudioPlayerSourceChanged)]);
}

export const reducerMiddleware = (storeAPI) => (next) => (action) => {
    const result = next(action);
    const states = storeAPI.getState();

    if (typeof Window !== 'undefined' && typeof localStorage !== 'undefined') {
        localStorage.setItem(
            LOCALSTORAGE_KEY,
            JSON.stringify({
                ...pick(states, [
                    'theme',
                    'playedBooks',
                    'viewedBooks',
                    'bookmarkedBooks',
                    'likedBooks',
                    'audioplayer',
                ]),
            })
        );
    }

    return result;
};
